import { graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

import ChapterPage from "../chapter/chapter";

const ClusterPage = ({ data }) => {
  return (
    <ChapterPage
      activeClusterId={data?.craftAPI?.entry?.id}
      data={{ craftAPI: { entry: data?.craftAPI?.entry } }}
    />
  );
};

export const clusterQuery = graphql`
  query ($uri: [String], $section: [String] = "exhibit") {
    craftAPI {
      entry(uri: $uri) {
        ...BreadcrumbNavigationFragment
        ... on CraftAPI_exhibit_cluster_Entry {
          id
          title
          uri
          audioAsset {
            ...AudioAssetFragment
          }
          coverImageCropStyle
          coverImagePositionOverride
          coverImage {
            ...ImageMetadataFragment
          }
          componentList {
            ...ComponentListFragment
          }
          heroType
          longDescription
          shortDescription
          ...DirectionNavigationFragment
        }
        # Chapter
        parent {
          ... on CraftAPI_exhibit_chapter_Entry {
            id
            uri
            coverImageCropStyle
            coverImagePositionOverride
            coverImage {
              ...ImageMetadataFragment
            }
            audioAsset {
              ...AudioAssetFragment
            }
            heroType
            longDescription
            shortDescription
            fontType
            theme
            title
            subtitle
            formattedTitleOverride
            backgroundImage {
              url
            }
            fontColor
            # Pillar
            parent {
              ... on CraftAPI_exhibit_pillar_Entry {
                id
                title
                uri
                pillarTitleColor
                theme
                fontType
                children {
                  id
                }
                # Exhibit
                parent {
                  ... on CraftAPI_exhibit_exhibit_Entry {
                    presentationType
                    theme
                    fontType
                    children {
                      id
                    }
                  }
                }
              }
            }
            # Sibling Clusters
            children {
              ...ClusterNavigationFragment
            }
          }
        }
      }
    }
  }
`;

ClusterPage.propTypes = {
  data: PropTypes.shape({
    craftAPI: PropTypes.shape({
      entry: PropTypes.shape({
        id: PropTypes.string,
        parent: PropTypes.shape({}),
      }),
    }),
  }),
};

export default ClusterPage;
